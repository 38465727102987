<template>
    <div class="main-content">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">
                                {{ $t("locations.location") }}
                            </h6>
                            <h1 class="header-title">
                                {{ $t("doors.doors") }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-header-title"></h4>
                            <div class="col-auto">
                                <form class="mr-3">
                                    <Select2
                                        v-model="perPage"
                                        :options="perPageOptions"
                                        :settings="{
                            minimumResultsForSearch: -1,
                            containerCssClass:
                            'custom-select custom-select-sm form-control-flush',
                            dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                        }"
                                        @change="paginatedList"
                                    />
                                </form>
                            </div>
                            <div class="col-auto">
                                <b-button variant="primary" @click="createDevice">
                                    {{ $t("doors.create") }}
                                </b-button>
                            </div>
                            <div class="col-auto">
                                <form>
                                    <b-button-group class="form-control-height">
                                        <button
                                            class="btn btn-white"
                                            @click.stop.prevent="pageView('list')"
                                            v-bind:class="{ active: viewMode == 'list'}"
                                        >
                                            <span class="fe fe-list"></span>
                                        </button>
                                        <button
                                            class="btn btn-white"
                                            @click.stop.prevent="pageView('card')"
                                            v-bind:class="{ active: viewMode == 'card'}"
                                        >
                                            <span class="fe fe-grid"></span>
                                        </button>
                                    </b-button-group>
                                </form>
                            </div>
                        </div>
                        <div class="card-header">
                            <!-- Form -->
                            <form>
                                <div class="input-group input-group-flush input-group-merge">
                                    <!-- Input -->

                                    <b-form-input
                                        :placeholder="$t('common.search')"
                                        v-model="filter"
                                        class="form-control form-control-prepended search"
                                        type="search"
                                    ></b-form-input>

                                    <!-- Prepend -->
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-body">
                            <div
                                class="tab-pane show active"
                                v-show="viewMode == 'list'"
                                transition="fade"
                            >
                                <!--  key="1" -->
                                <div class="row list">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="devicesList.length"
                                        :per-page="perPage"
                                        aria-controls="my-table"
                                        class="pb-2"
                                    ></b-pagination>
                                    <zs-list-transition
                                        :showLoader="showLoader"
                                        class="w-100"
                                    >
                                        <template v-slot:content>
                                            <!-- v-for="device in devicesList" -->
                                            <door-card-item
                                                v-for="device in paginatedList"
                                                v-bind:key="device.Device.Id"
                                                :device="device"
                                            />
                                        </template>
                                        <template v-slot:empty>
                                            <empty-list :title="$t('doors.no_doors')"/>
                                        </template>
                                    </zs-list-transition>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="devicesList.length"
                                        :per-page="perPage"
                                        aria-controls="my-table"
                                        class="pb-2"
                                    ></b-pagination>
                                </div>
                            </div>

                            <div
                                class="tab-pane show active"
                                v-show="viewMode == 'card'"
                                transition="fade"
                            >
                                <!-- key="2"  -->
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="devicesList.length"
                                    :per-page="perPage"
                                    aria-controls="my-table"
                                    class="pb-2"
                                ></b-pagination>
                                <div class="row listAlias" v-if="devicesList.length">

                                    <!-- v-for="device in devicesList" -->
                                    <door-large-card-item
                                        v-for="device in paginatedList"
                                        v-bind:key="device.Device.Id"
                                        :device="device"
                                    />
                                </div>
                                <empty-list v-else :title="$t('doors.no_doors')"/>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="devicesList.length"
                                    :per-page="perPage"
                                    aria-controls="my-table"
                                    class="pb-2"
                                ></b-pagination>
                            </div>
                            <!-- </transition> -->
                        </div>

                        <zs-device-modal
                            v-if="getActiveLocation"
                            :locationId="getActiveLocation.Id"
                        />
                    </div>

                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ListTransition from "@/components/common/ListTransition";
import DoorCardItem from "@/components/device/DoorCardItem";
import DoorLargeCardItem from "@/components/device/DoorLargeCardItem";
import CreateDeviceModal from "@/components/modals/CreateDevice";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";

import {mapGetters} from "vuex";

import {LOCATION_GET} from "@/store/types/locations";
import {DEVICES_GET} from "@/store/types/devices";

import {SET_BREADCRUMBS, SET_MODAL_DATA} from "@/store/types/global";
import {M_DEVICE_FORM} from "@/components/modals/types";

export default {
    data() {
        return {
            filter: "",
            showLoader: true,
            viewMode: "",
            currentPage: 1,
            perPage: 20,
            perPageOptions: [
                {id: 10, text: "10 " + this.$t("menu.per_page")},
                {id: 20, text: "20 " + this.$t("menu.per_page")},
                {id: 50, text: "50 " + this.$t("menu.per_page")},
            ],
        };
    },
    computed: {
        ...mapGetters(["getLocationDevices", "getActiveLocation"]),
        devicesList() {
            return this.getLocationDevices.filter(
                (item) =>
                    item.Device.Name.toLowerCase().includes(
                        this.filter.toLowerCase()
                    ) && item.ProviderType != 2
            )
                .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
                });
        },
        paginatedList() {
            return this.devicesList.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
    },

    created() {
        if (this.$session.get("pageViewDoor")) {
            this.viewMode = this.$session.get("pageViewDoor");
        } else {
            this.viewMode = 'list';
        }
        this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
        this.$store
            .dispatch(DEVICES_GET, this.$route.params.locationId)
            .then(() => (this.showLoader = false));
    },
    methods: {
        pageView(view) {
            this.$session.set("pageViewDoor", view);
            this.viewMode = view;
        },
        createDevice() {
            this.$store.commit(SET_MODAL_DATA, {
                data: null,
                type: M_DEVICE_FORM,
            });
            this.$bvModal.show(M_DEVICE_FORM);
        },
    },
    components: {
        "zs-device-modal": CreateDeviceModal,
        "door-card-item": DoorCardItem,
        "door-large-card-item": DoorLargeCardItem,
        EmptyList,
        "zs-list-transition": ListTransition,
        Select2,
    },
};

</script>
<style lang="sass" scoped>
.fade-enter-active,
.fade-leave-active
    transition: opacity .15s

    .fade-enter,
    .fade-leave-to
        opacity: 0
</style>
